module.exports = {
  comm: {
    home: '主页',
    pricing: '定价',
    blog: '语音克隆&API',
    language: '语言：',
    select_language: '选择语言',
    txt2voice: '文字转语音',
    voice2txt: '语音转文字',
    voiceclone: '语音克隆',
    video2txt: '视频转文字',
    footer_help: '需要帮助？联系我们：',
    footer_mail: 'tiktokaivoicetool@gmail.com',
    login: '登录',
    login_desc: '登录以访问您的剪映 AI 配音账户',
    logout: '退出登录',
  },
  txt2voice: {
    title: '在线免费文字转语音:生成剪映AI配音',
    description: '免费合成剪映中的AI配音，任意文字转语音的在线AI视频配音助手，支持小帅，猴哥，熊二，紫薇，新闻腔等热门AI配音',
    keywords: '文字转语音，AI配音，视频配音助手，剪映',
    main_title_p01: '文字转语音助手-剪映AI配音神器',
    main_title_p02: '支持小帅，猴哥，熊二，紫薇，新闻腔等热门AI配音',
    main_textarea_holder: '在此输入或粘贴文本',
    main_genvoice: '合成',
    main_generating: '合成中',
    main_input_empty: '请输入或粘贴文本',
    daily_usage_limit_msg: '1) 已达到今日最大使用次数，请明天再来。 2) 如果急需更多使用次数，请给我们发邮件。',
    text_max_prompt1: '请将字数限制在',
    text_max_prompt2: '字以内！',
    popup_nologin_title: '升级您的订阅计划',
    popup_nologin_desc: '通过升级到高级订阅来解锁更多语音生成时间。',
    popup_nologin_btntext: '探索订阅计划',
    popup_nosub_title: '升级您的订阅计划',
    popup_nosub_desc: '通过升级到高级订阅来解锁更多语音生成时间。',
    popup_nosub_btntext: '探索订阅计划',
    popup_sublimit_title: '订阅已用尽，请升级',
    popup_sublimit_desc: '通过我们的高级功能和无限访问来提升您的体验。',
    popup_sublimit_btntext: '前往购买更多',
  },
  pricing: {
    new_features_alert: "📣 我们已推出 🎙️ 语音克隆和 🤖 API 服务，供付费会员使用！(付款后，请发送电子邮件至 tiktokaivoicetool@gmail.com 获取这些功能的帮助)。此外，随时通过电子邮件与我们联系以获取任何定制请求😃",
    pricing_title: '从个人创作者到大型企业，我们为您提供合适的计划。',
    pricing_desc: '100多种自然、类人的声音。15种以上语言。几乎是每个人的最低价格',
    sub_free_name: '免费',
    sub_free_desc: '适合想尝试最先进AI音频的个人',
    sub_free_content: [
      '每月1000字符限制',
      '每天5次生成',
    ],
    sub_starter_name: '入门版',
    sub_starter_desc: '适合为全球受众制作优质内容的创作者',
    sub_starter_content: [
      '1个语音克隆',
      '每月300000字符限制（约7小时音频）',
      '无限下载',
      '72小时邮件支持',
      '优先访问新声音和功能',
    ],
    sub_pro_name: '专业版',
    sub_pro_desc: '适合加大内容制作的创作者',
    sub_pro_content: [
      'API访问',
      '3个语音克隆',
      '每月1000000字符限制（约24小时音频）',
      '无限下载',
      '48小时邮件支持',
      '优先访问新声音和功能',
    ],
    period_year: '年付',
    period_month: '月付',
    period_month_short: '月',
    billed_year: '按年计费',
    most_popular: '最受欢迎',
    discount_quantity: '节省$48',
    buy_btntext: '开始使用',
    pay_succ: '支付成功！',
    pay_succ_desc: '感谢您的购买。您的交易已成功完成。',
    pay_succ_btntext: '开始使用TikTok语音',
    pay_fail: '支付失败！',
    pay_fail_desc: "我们很抱歉，但您的交易无法处理。请重试或联系我们的支持团队寻求帮助，邮箱: tiktokaivoicetool@gmail.com。",
    pay_fail_btntext: '返回首页',
  },
  setting: {
    setting: '设置',
    setting_title: '在这里管理您的账户、使用情况和订阅。',
    basic_info: '基本信息',
    user_name: '姓名',
    user_email: '邮箱',
    user_account: '账户',
    user_subscript: '当前计划',
    user_usage: '使用情况',
    manage_subscript: '管理订阅',
    use_limit_promote: "您已使用每月10000字符限制中的5000字符。",
    not_logged_in: '您尚未登录',
    go_to_login: '前往登录 >>',
  },
  login: {
    title: "抖音语音生成器",
    subtitle: "登录以访问您的抖音语音生成器账户",
    alreadyLoggedIn: "您已经登录",
    goToHome: "返回首页"
  },
  faq: {
    quest1: '剪映文字转语音助手 - 最佳剪映AI配音软件',
    answer1_1: "这是一个由最新的文字转语音技术开发的剪映AI配音合成软件，可以合成热门的剪映AI配音和抖音音效，比如小帅，猴哥，熊二，舌尖上的中国，新闻腔等中文配音，另外，也支持热门的剪映国际版AI配音，比如： Jessie语音（女性语音）、Siri 语音、鬼脸语音、C3PO 语音（机器人语音）、深沉语音（故事讲述者）、Skye 语音、温暖语音、Bestie 语音、英雄语音（Chris 声音）、富有同情心的语音、严肃语音、Joey 语音、Stitch 语音、风暴兵语音（星球大战）、Rocket 语音（银河护卫队）。",
    answer1_2: "即将支持的剪映国际版AI配音包括：Derek 语音、Trickster 语音、Austin Butler 语音、新闻播报员语音、Adam 语音、狗语音、Miley Cyrus 语音、Alexa 语音、外星人语音、动物语音、婴儿语音、电脑语音、花栗鼠语音、回声语音、NPR 语音。",
    answer1_3: '此外，剪映文字转语音助手支持多种其他语言的AI配音，包括中文、日语、韩语、越南语、泰语、印地语、波斯语、俄语、德语、法语、罗马尼亚语、捷克语、西班牙语、葡萄牙语、孟加拉语、意大利语、阿拉伯语、乌尔都语、繁体中文和马来语。',
    answer1_4: '如果您急需某个特定中文配音或其他语言的AI配音，请发送电子邮件给我。',
    
    quest2: '剪映文字转语音助手的优势是什么？',
    answer2_1: '- 剪映文字转语音助手可以生成多种类型的语音，通常用于抖音视频中。',
    answer2_2: "- 这是最新的AI配音软件，可以生成接近人类的语音。",
    answer2_3: '- 在笔记本和台式电脑上进行视频编辑更加方便。',
    answer2_4: "- 您可以使用一些剪映中无法找到的语音。",

    quest3: '如何使用剪映文字转语音助手？',
    answer3_1: '剪映文字转语音助手非常易于使用：',
    answer3_2: '- 选择语言和语音口音。',
    answer3_3: '- 在文本框中输入要转换为语音的文字，',
    answer3_4: '- 按合成按钮，等待几秒钟，',
    answer3_5: '- 播放或下载配音文件。',

    quest4: '最著名和最有趣的剪映AI配音是什么？',
    answer4_1: '最著名的男性剪映配音，是小帅。',
    answer4_2: "对于最有趣的剪映配音，我个人推荐猴哥和熊二的配音。这些语音由于其高度识别性，经常用于搞笑的抖音视频中，并能迅速让观众沉浸其中。",
   
    quest5: '最烦人的剪映AI配音是什么？',
    answer5_1: "我认为不存在真正'烦人'的剪映AI配音。",
    answer5_2: "更重要的是语音使用的上下文和内容，可能会让某些人感到不舒服。例如，有些人可能会觉得小帅配音很烦，因为它在某一时期在抖音上出现得太频繁了，比如你经常能听到：注意看，这个男人叫小帅。",
    answer5_3: '所以，我建议大家放下顾虑和成见，多次聆听本工具的语音，耐心找到最适合自己的剪映文字转语音的效果。',

    quest6: '如何使用剪映文字转语音助手合成猴哥的AI配音？',
    answer6_1: '- 在剪映文字转语音助手的第一个下拉菜单中，选择中文口音，然后在另一个下拉菜单中选择猴哥的配音。',
    answer6_2: '- 将文本输入输入框中，然后点击合成按钮。',
    answer6_3: '- 等待大约十秒钟，您将听到AI合成的配音。在操作栏中，找到带有向下箭头的按钮并点击下载。',

    quest7: '如何使用剪映文字转语音助手合成这个男人叫小帅的AI配音？',
    answer7_1: '- 选择英语中文口音，然后选择小帅。',
    answer7_2: '- 输入文本并点击合成按钮。',
    answer7_3: '- 等待片刻，您将听到AI配音。在操作栏中，找到带有向下箭头的按钮并点击下载。',

    quest8: '如何使用剪映文字转语音助手合成熊二的AI配音？',
    answer8_1: '- 选择中文，然后选择熊二。',
    answer8_2: '- 输入或粘贴文本并按合成语音按钮。',
    answer8_3: '- 剪映文字转语音助手就会立即合成配音，您可以播放它。',

    quest9: '如何使用剪映文字转语音助手合成还珠格格中紫薇的AI配音？',
    answer9_1: '- 选择中文，然后点击紫薇的语音。',
    answer9_2: '- 将文本放入输入框中并按生成语音按钮。',
    answer9_3: '- 紫薇的AI语音将被合成。',

    quest10: '如何在抖音视频中添加剪映AI配音？',
    answer10_1: '如果您想知道如何在抖音上使用官方剪映的语音，我会很快写一篇专门的博客文章讨论这个话题。',
    answer10_2: '在这里，我将解释如何在生成并下载语音后，将您的语音发布到抖音。',
    answer10_3: '1. 如果您已经在 PC 上生成了剪映AI配音并想将其上传到抖音 或其他视频编辑软件到手机上，您需要将语音文件传输到手机。对于 iPhone，您可以使用 AirDrop 进行传输。对于安卓手机，我不太熟悉这个过程，但您肯定可以找到相应的方法和工具。',
    answer10_4: '2. 将语音文件传输到手机后，您可以打开抖音：',
    answer10_5: "- 点击界面底部的 '+' 按钮，选择手机中的视频。",
    answer10_6: '- 上传视频后，在屏幕右侧找到一个方形图标进入编辑页面。',
    answer10_7: "- 在页面底部找到'添加语音'按钮，并点击选择刚刚传输的语音文件。",
    answer10_8: '- 在此界面中，您可以对视频进行一些调整，然后点击右上角的按钮发布。',

    quest11: '剪映AI配音助手是免费吗？',
    answer11: '是的，这是一个免费的剪映AI文字转语音在线软件，最好的视频AI配音助手。',

quest12: '在哪里可以生成流行的语音到我的视频中？',
    answer12: '你可以访问https://tiktokvoice.net/来将tiktok AI语音添加到你的视频中。'
  },
  
  // host: 'http://tiktokvoice.net'
  host: 'https://tiktokvoice.net'
}